import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import LaunchTeamForm from '../components/LaunchTeamForm';

export const LaunchPageTemplate = ({ image, title, subtitle, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <Hero
        image={!image.childImageSharp ? image : image.childImageSharp.fluid}
        title={title}
        subtitle={subtitle}
      />
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">{title}</h2>
              <p className="content is-size-5">
                Thanks so much for joining the launch team! We will let you know the next steps! Key
                dates to remember for sharing the site: July 27-August 14.
              </p>

              <LaunchTeamForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

LaunchPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const LaunchPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <LaunchPageTemplate
        contentComponent={HTMLContent}
        image={post.frontmatter.image}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        content={post.html}
      />
    </Layout>
  );
};

LaunchPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LaunchPage;

export const launchPageQuery = graphql`
  query LaunchPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

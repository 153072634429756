/* eslint-disable no-alert */
import React, { useState } from 'react';
import { navigate } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const EmailListForm = (props) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.currentTarget.value);
  };
  const handleLastNameChange = (event) => {
    setLastName(event.currentTarget.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.length === 0) {
      alert('Please enter your email');
      return;
    }
    if (firstName.length === 0) {
      alert('Please enter your first name');
      return;
    }
    if (lastName.length === 0) {
      alert('Please enter your last name');
      return;
    }
    addToMailchimp(email, {
      FNAME: firstName,
      LNAME: lastName,
      LOCATION: 'launch',
      URL: '/launchteam',
    }).then((data) => {
      if (data.result === 'success') {
        navigate('/launchthanks');
      } else {
        alert(`There was a problem. If this keeps happening, please email us at hello@cpeu.org. Error: ${data.msg}`);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <section>
        <div>
          <input
            className="input"
            placeholder="First Name"
            name="firstName"
            type="text"
            onChange={handleFirstNameChange}
          />
          <input
            className="input"
            placeholder="Last Name"
            name="lastName"
            type="text"
            onChange={handleLastNameChange}
          />
          <input
            className="input"
            placeholder="Email address"
            name="email"
            type="text"
            onChange={handleEmailChange}
          />
        </div>
      </section>
      <button className="button is-primary" type="submit">
        Sign up
      </button>
    </form>
  );
};

export default EmailListForm;
